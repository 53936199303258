<template>
  <div class="user-manage">
    <table-search :total="total" :conditions="conditions" @searchRes="searchRes" @refresh="getList">
    </table-search>
    <div class="table">
      <el-table
        :data="tableData"
        border
        @selection-change="handleSelectionChange"
        style="width: 100%">
        <el-table-column
          prop="id"
          label="聊天ID">
        </el-table-column>
        <el-table-column
          prop="roomId"
          label="会话ID">
        </el-table-column>
        <el-table-column
          prop="msg"
          label="聊天内容">
        </el-table-column>
        <el-table-column
          prop="accountId"
          label="发送用户ID">
        </el-table-column>
        <!-- <el-table-column
          prop="caseId"
          label="接受用户ID">
        </el-table-column> -->
        <el-table-column
          prop="createTime"
          label="发送时间">
        </el-table-column>
        <!-- <el-table-column
          prop="caseId"
          label="招标ID">
        </el-table-column> -->
        <el-table-column
          prop="caseId"
          label="投标ID">
        </el-table-column>
        <el-table-column
          prop="msgType"
          label="消息类型">
          <template slot-scope="scope">
            <span v-show="scope.row.msgType==1">文本</span>
            <span v-show="scope.row.msgType==2">图片</span>
            <span v-show="scope.row.msgType==3">音频</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="timeInfo"
          label="消息时长">
        </el-table-column>
        <el-table-column
          prop="val"
          label="操作">
          <template slot-scope="scope">
            <el-button type="text">介入</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer flex-row">
      <div class="page">
        <el-pagination
          background
          :page-size="form.perPage"
          @size-change="handleSizeChange"
          layout="prev, pager, next,sizes,jumper"
          :page-sizes="[10, 20, 30, 40]"
          @current-change="currentChange"
          :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import TableSearch from '../../components/table-search.vue'
import {connectList} from '../../api/service'
export default {
  data () {
    return {
      total:0,
      form:{
        perPage:10,
        page:1
      },
      conditions:[
        {value:'accountId',label:'发送用户ID'},
        // {value:'2',label:'接受用户ID'},
        // {value:'3',label:'招标ID'},
        {value:'caseId',label:'投标ID'},
        {value:'roomId',label:'会话ID'},
      ],
      tableData:[
        {
          val:'1',
          id:1,
          status:'1'
        },
        {
          val:'2',
          id:2,
          status:'2'
        },
        {
          val:'3',
          id:3,
          status:'3'
        },
        {
          val:'4',
          id:4,
          status:'4'
        }
      ],
      multipleSelection:[],
        statusFilter:[
          {text: '正常', value: '1'}, 
          {text: '禁言', value: '2'}, 
          {text: '禁止登陆', value: '3'}
        ],
        applyFilter:[
          {text: '诉讼业务', value: '1'}, 
          {text: '婚姻家庭', value: '2'}, 
          {text: '合同纠纷', value: '3'},
          {text: '非诉讼业务', value: '4'},
          {text: '法律顾问', value: '5'},
          {text: '劳动纠纷', value: '6'},
          {text: '金融证券', value: '7'},
          {text: '公司经营', value: '8'},
          {text: '法律文书', value: '9'},
        ],
        typeFilter:[
            {text: '等待选标中', value: '1'},
            {text: '已中标', value: '2'}, 
            {text: '未中标', value: '3'}
        ],
        subjectFilter:[
            {text: '招标审核成功', value: '1'},
            {text: '招标审核失败', value: '2'}, 
            {text: '开标通知', value: '3'}, 
            {text: '邀请招标通知', value: '4'}, 
            {text: '投标中标通知', value: '5'}, 
            {text: '投标未中标通知', value: '6'}, 
            {text: '招标被终止', value: '7'}, 
            {text: '被邀请投标方', value: '8'}, 
            {text: '招标结标公示', value: '9'}, 
            {text: '商机推送', value: '9'}
        ],
        payFilter:[
            {text: '已读', value: 1},
            {text: '未读', value: 0}, 
        ]

    };
  },
  components: {
    TableSearch
  },
  computed: {},
  methods: {
    getList(){
      connectList(this.form).then(res=>{
        let data = res.data;
        if(data.code === '000'){
          this.tableData = data.content.list;
          this.total = data.content.total;
        }else{
          console.log(data.desc)
        }
      })
    },
    searchRes(para){
       this.form = Object.assign({page:1,perPage:this.form.perPage},para);
       console.log(para,this.form)
       this.getList();
    },
    // 分页
    currentChange(val){
      this.form.page = val;
      this.getList();
    },
    handleSizeChange(val){
      this.form.perPage = val;
      this.form.page = 1;
      this.getList();
    },
    addHandle(){},
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(val)
    },
    checkHandle(row){},
    userHandle(row){},
    editHandle(row){},
    forbidAll(){},
    forbid(){},
    filterHandler(value, row, column) {
      const property = column['property'];
      return row[property] === value;
    }
  },
  created(){
    this.getList();
  }
}

</script>
<style lang='scss' scoped>
.footer{
  justify-content: flex-end;
  margin:15px 0;
}
.dot{
  font-size: 20px;
  padding-right: 5px;
  vertical-align:middle;
}
</style>